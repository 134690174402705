import React from "react"

// Libraries
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Trans } from "gatsby-plugin-react-i18next"

// Utils
import breakpoint from "utils/breakpoints/"

// Components
import Section from "components/case-study-section/"

const StyledSection = styled(Section)`
  .section__animation {
    margin: 32px 0;

    ${breakpoint.medium`
      margin: 80px 0;
    `}
  }
`

const Challange = () => {
  const { file: image } = useStaticQuery(graphql`
    query {
      file(
        relativePath: { eq: "case-studies/indicius/the-challenge-image.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 928, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <StyledSection>
      <div className="container">
        <div className="section__title">
          <div className="sticky">
            <p>
              <Trans>The challenge</Trans>
            </p>
          </div>
        </div>

        <div className="section__content">
          <h3>
            <Trans>Ditching the pixels, becoming humans.</Trans>
          </h3>
          <br />
          <p>
            <Trans>
              Indicius' website, 5 years after its creation, was still cool and
              functional, but as a company it wasn’t reflecting the internal
              journey we had recently done in reshaping our services, mission,
              manifesto & goals. It wasn’t showing the real us.
            </Trans>
          </p>

          <br />

          <p>
            <Trans>
              But just thinking about a website refresh was hard, because we
              also had some issues with our branding, which wasn’t allowing us
              to scale. For example, we had:
            </Trans>
          </p>

          <br />

          <ul className="list">
            <li>
              <Trans>
                A logo dependent system, where if you didn't use the logo in a
                composition it felt like it didn't belong to Indicius
              </Trans>
            </li>
            <li>
              <Trans>Too many limitations in the color palette</Trans>
            </li>
            <li>
              <Trans>
                A dull and outdated font that wasn't helping us enhance the
                system
              </Trans>
            </li>
            <li>
              <Trans>
                A Frankenstein of materials that didn't represent our brand
                consistently, which we had accumulated along the way
              </Trans>
            </li>
          </ul>

          <br />

          <p>
            <Trans>
              We made the decision, as a team, to rebrand Indicius, and together
              we began a collaborative adventure into ourselves to create a
              people-first brand.
            </Trans>
          </p>

          <h4 className="mt-5 mb-6 font-weight--700">
            <Trans>SHAPE EXPLORATIONS</Trans>
          </h4>
        </div>
      </div>

      <div className="container">
        <Img
          fluid={image.childImageSharp.fluid}
          style={{ width: "100%", display: "block" }}
          alt="Shape Explorations"
        />
      </div>
    </StyledSection>
  )
}

export default Challange
