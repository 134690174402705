import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

// Libraries
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Section from "components/case-study-section/"
import LinkWithIcon from "components/external-link-with-icon/"
import Carousel from "components/case-study-carousel/"
import Lottie from "components/lottie/"

// Animations
import Animation from "assets/animations/case-studies/indicius/carousel-animation.json"

// Icons
import IconExternal from "assets/icons/case-studies/indicius/icon-external.inline.svg"
import IconBackground from "assets/icons/case-studies/indicius/icon-external-background.svg"

const StyledSection = styled(Section)`
  padding-top: 56px !important;

  ${breakpoint.medium`
    padding-top: 120px!important;
  `}

  .the-results__brand-guide-link {
    padding: 32px 24px;
    border: 1px solid ${colors.cloudy};
    background-image: url("${IconBackground}");
    background-size: 182px 58px;
    background-position: calc(100% + 154px);
    background-repeat: no-repeat;

    &:hover {

      svg {

        circle {
          fill: ${colors.midnight};
        }
      }
    }

    ${breakpoint.small`
      padding: 32px 40px;
      background-position: 100%;
    `}

    svg {
      circle {
        transition: all 0.3s ease;
      }
    }
  }
`

const StyledCarousel = styled(Carousel)`
  margin-top: 40px;

  ${breakpoint.medium`
    margin-top: 80px;
  `}
`

const Results = () => {
  const data = useStaticQuery(graphql`
    query {
      nicholasPhoto: file(
        relativePath: { eq: "case-studies/acgen/nicholas-tatonetti.png" }
      ) {
        childImageSharp {
          fixed(width: 48, height: 48, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

      carouselImageOne: file(
        relativePath: { eq: "case-studies/indicius/results-image-1.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 928, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      carouselImageTwo: file(
        relativePath: { eq: "case-studies/indicius/results-image-2.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 928, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      carouselImageThree: file(
        relativePath: { eq: "case-studies/indicius/results-image-3.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 928, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      carouselImageFour: file(
        relativePath: { eq: "case-studies/indicius/results-image-4.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 928, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      carouselImageFive: file(
        relativePath: { eq: "case-studies/indicius/results-image-5.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 928, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const { t } = useTranslation()

  return (
    <StyledSection>
      <div className="container">
        <div className="section__title">
          <div className="sticky">
            <p>
              <Trans>The results</Trans>
            </p>
          </div>
        </div>

        <div className="section__content">
          <h3>
            <Trans>Rebranded for the long term</Trans>
          </h3>
          <br />
          <p>
            <Trans>
              We learned the lesson that rebranding takes time. But it’s worth
              it: this hard look in the mirror helped us become more human and
              got us to the starting point of something bigger.
            </Trans>
          </p>

          <br />

          <p>
            <Trans>
              The system has unexplored adventures and we love to see how, not
              only designers, but also the commercial team and project managers
              are playing, discovering and creating new stuff.{" "}
            </Trans>
          </p>

          <br />

          <p>
            <Trans>
              So, this is just the beginning. We are ready to start designing a
              more human-centered world. Together.
            </Trans>
          </p>

          <br />

          <a
            href="https://brand.indicius.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="the-results__brand-guide-link d-flex align-items-center font-weight--500"
          >
            <IconExternal className="me-2" />
            <Trans>Launch the brand guide</Trans>
          </a>
        </div>
      </div>

      <StyledCarousel id="resultsCarousel">
        <div data-slide className="slide align--with-title">
          <Lottie animation={Animation} autoplay={true} loop={true} />
        </div>
        <div data-slide className="slide">
          <Img
            fluid={data.carouselImageTwo.childImageSharp.fluid}
            alt="ACGEN"
          />
        </div>
        <div data-slide className="slide">
          <Img
            fluid={data.carouselImageThree.childImageSharp.fluid}
            alt="ACGEN"
          />
        </div>
        <div data-slide className="slide">
          <Img
            fluid={data.carouselImageFour.childImageSharp.fluid}
            alt="ACGEN"
          />
        </div>
        <div data-slide className="slide">
          <Img
            fluid={data.carouselImageFive.childImageSharp.fluid}
            alt="ACGEN"
          />
        </div>
      </StyledCarousel>
    </StyledSection>
  )
}

export default Results
