import React from "react"

// Libraries
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

// Utils
import { colors } from "utils/variables/"
import breakpoint from "utils/breakpoints/"

// Components
import Section from "components/case-study-section/"
import Lottie from "components/lottie/"

// Animations
import Animation from "assets/animations/case-studies/indicius/explorations-animation.json"
import Animation2 from "assets/animations/case-studies/indicius/explorations-animation-2.json"

const StyledSection = styled(Section)`
  overflow-x: hidden;
  background-color: ${colors.cream};
`

const ImageWrapper = styled.div`
  max-width: 1280px;
  margin: 0 auto;

  .image {
    max-width: calc(100% - 16px);
    display: block;
    margin-bottom: 32px;

    ${breakpoint.small`
      max-width: 75%;
    `}

    ${breakpoint.medium`
      margin-bottom: 80px;
    `}

    ${breakpoint.large`
      max-width: 920px;
    `}

    &:nth-child(even) {
      margin-left: auto;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`

const Exploration = () => {
  const data = useStaticQuery(graphql`
    query {
      imageOne: file(
        relativePath: { eq: "case-studies/indicius/exploration-image-1.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 920, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      imageThree: file(
        relativePath: { eq: "case-studies/indicius/exploration-image-3.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 920, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <StyledSection>
      <ImageWrapper>
        <div
          className="image"
          data-aos="indicius-slide-right"
          data-aos-duration="600"
          data-aos-offset="180"
        >
          <Img fluid={data.imageOne.childImageSharp.fluid} alt="Explorations" />
        </div>
        <div
          className="image"
          data-aos="indicius-slide-left"
          data-aos-duration="600"
          data-aos-offset="180"
        >
          <Lottie animation={Animation} autoplay={true} loop={true} />
        </div>
        <div
          className="image"
          data-aos="indicius-slide-right"
          data-aos-duration="600"
          data-aos-offset="180"
        >
          <Img
            fluid={data.imageThree.childImageSharp.fluid}
            alt="Explorations"
          />
        </div>
        <div
          className="image"
          data-aos="indicius-slide-left"
          data-aos-duration="600"
          data-aos-offset="180"
        >
          <Lottie animation={Animation2} autoplay={true} loop={true} />
        </div>
      </ImageWrapper>
    </StyledSection>
  )
}

export default Exploration
