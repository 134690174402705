import React from "react"

// Libraries
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"

// Utils
import { colors } from "utils/variables/"
import breakpoint from "utils/breakpoints/"

// Components
import Section from "components/case-study-section/"
import Quote from "components/case-study-quote/"

const StyledQuote = styled(Quote)`
  margin-top: 40px;
`

const StyledSection = styled(Section)`
  .section__animation {
    margin: 32px 0;

    ${breakpoint.medium`
      margin: 80px 0;
    `}
  }

  .image-wrapper {
    margin: 0 auto 56px auto;

    &--mobile {
      ${breakpoint.small`
        display: none!important;
      `}
    }

    &--desktop {
      display: none !important;

      ${breakpoint.small`
        display: block !important;
      `}
    }

    ${breakpoint.medium`
      margin-bottom: 80px;
    `}
  }
`

const Solution = () => {
  const { imageMobile, imageDesktop, salvador } = useStaticQuery(graphql`
    query {
      imageMobile: file(
        relativePath: {
          eq: "case-studies/indicius/the-solution-image-mobile.png"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 560, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageDesktop: file(
        relativePath: {
          eq: "case-studies/indicius/the-solution-image-desktop.png"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 1120, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      salvador: file(
        relativePath: { eq: "case-studies/indicius/salvador.png" }
      ) {
        childImageSharp {
          fixed(width: 48, height: 48, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)
  const { t } = useTranslation()
  return (
    <StyledSection>
      <div className="container">
        <div className="section__title">
          <div className="sticky">
            <p>
              <Trans>The solution</Trans>
            </p>
          </div>
        </div>

        <div className="section__content">
          <h3>
            <Trans>A year-long collaborative in-house exploration</Trans>
          </h3>

          <br />

          <p>
            <Trans>The first step was research, of course!</Trans>{" "}
            <span role="img" aria-label="je">
              😅
            </span>{" "}
            <Trans>
              We did everything we could to deeply understand who we were and
              what people thought about us. Interviewing clients and partners
              helped us rediscover our value proposition and gather important
              insight that later, combined with our vision and goals, was able
              to help us define the Indicius brand DNA and its tone of voice.
            </Trans>
          </p>

          <br />

          <p>
            <Trans>
              The ideation stage was about exploration, and yes we explored, a
              lot… A quick session of benchmarking and Lightning Demos of
              well-implemented branding was followed by many tests of
              typographies and color palettes.
            </Trans>
          </p>

          <br />

          <p>
            <Trans>
              We played with the logo until one day we found the perfect shape
              that represents the old and the new Indicius--a glorious day for
              the team.
            </Trans>
          </p>

          <br />

          <p>
            <Trans>
              When we were able to polish all the exploration into two paths, we
              showed it to the rest of the team and gathered the feedback we
              were looking for to close this brand out.{" "}
            </Trans>
          </p>

          <br />

          <p>
            <Trans>
              After many long conversations, we decided to launch the identity
              with a new website, so we took a big deep breath, embraced the new
              challenge and started designing.
            </Trans>
          </p>

          <br />

          <p>
            <Trans>
              The website stage was even more collaborative than the branding
              stage.
            </Trans>
          </p>

          <br />

          <p>
            <Trans>
              After creating a first version of the site content we invited ALL
              THE DESIGNERS ON THE TEAM, to create a Style Tile with the new
              branding and later, in a workshop, we voted on the best ideas.
            </Trans>
          </p>

          <h4 className="mt-5 mb-6 font-weight--700">
            <Trans>STYLE TILE EXPLORATIONS</Trans>
          </h4>
        </div>
      </div>

      <Img
        className="image-wrapper image-wrapper--mobile"
        fluid={imageMobile.childImageSharp.fluid}
        style={{ width: "100%", display: "block" }}
        alt="STYLE TILE EXPLORATIONS"
      />

      <Img
        className="image-wrapper image-wrapper--desktop"
        fluid={imageDesktop.childImageSharp.fluid}
        style={{
          maxWidth: "1120px",
          width: "100%",
          display: "block",
        }}
        alt="STYLE TILE EXPLORATIONS"
      />

      <div className="container">
        <div className="section__title"></div>
        <div className="section__content">
          <p>
            <Trans>
              Another important element of this stage was a research process
              that led to our consolidated brand content guidelines. This helped
              us find our voice so we could create the content for the website.
              Then, with a bag full of visual, interactive ideas and defined
              content, we started designing the website. The development, which
              was incredibly in sync with all the design stages, was also done
              in-house.{" "}
            </Trans>
          </p>

          <br />

          <p>
            <Trans>
              Before shipping everything out we tested the site with partners,
              friends and clients, and even though we felt like we couldn't wait
              anymore we implemented user feedback before the launch.
            </Trans>
          </p>

          <StyledQuote
            quote={t(
              "“What I loved about this project was that we did it 100% in-house. Everyone from the team had the chance to collaborate”"
            )}
            author="Salvador Fernández"
            position={t("Head of Design")}
            image={salvador.childImageSharp.fixed}
            backgroundColor={colors.indiblue}
            color={colors.white}
          />
        </div>
      </div>
    </StyledSection>
  )
}

export default Solution